import React from 'react';
import { ThemeProvider, breakpoints } from '@gcc/autobahn-components';
import styled from 'styled-components';
import useFixedHarmonyTheme from '../../hooks/_useFixedHarmonyTheme';
import PcpMicroApp from './PcpMicroApp';

export const AppContainer = styled.div`
  ${breakpoints.s} {
    padding-top: 20px;
    background: white;
  }
`;

const globalStyle = `{
  a {
    color: inherit;
  }
}`;

export const PcpApp = () => {
  const themeHarmony = useFixedHarmonyTheme();
  return (
    <AppContainer id="micro-pcp">
      <ThemeProvider theme={themeHarmony} globalStyles={globalStyle}>
        <PcpMicroApp />
      </ThemeProvider>
    </AppContainer>
  );
};

PcpApp.propTypes = {
};

PcpApp.displayName = 'PcpApp';

PcpApp.defaultProps = {
  name: '',
};
